import { createObservableDataAction, ICreateActionContext, IAction } from '@msdyn365-commerce/core';
import {
    IDimensionForSelectedVariant,
    getDimensionsForSelectedVariantAction,
    getSelectedProductIdFromActionInput,
    GetDimensionsForSelectedVariantInput
} from '@msdyn365-commerce-modules/retail-actions';

export class GetAllDimensionsForProductInput extends GetDimensionsForSelectedVariantInput {
    constructor(productId: number, channelId: number) {
        super(productId, channelId, []);
    }

    public getCacheObjectType = () => 'HEI-AllDimensionsForProduct';
}

const createAllDimensionsForProductInput = (inputData: ICreateActionContext) => {
    const productId = getSelectedProductIdFromActionInput(inputData);

    // process.env.NODE_ENV === 'development' &&
    //     console.log('hei-get-all-dimensions-for-product', 'createAllDimensionsForProductInput', productId, inputData);

    if (productId) {
        return new GetAllDimensionsForProductInput(+productId, +inputData.requestContext.apiSettings.channelId);
    }

    throw new Error('Unable to create AllDimensionsForProductInput, no productId found on module config or query');
};

export const getAllDimensionsForProductActionDataAction = createObservableDataAction({
    id: 'hei-get-all-dimensions-for-product',
    action: <IAction<IDimensionForSelectedVariant[]>>getDimensionsForSelectedVariantAction,
    input: createAllDimensionsForProductInput
});

export default getAllDimensionsForProductActionDataAction;
