import * as Msdyn365 from '@msdyn365-commerce/core';
import {
    QueryResultSettingsProxy,
    generateProductImageUrl,
    createInventoryAvailabilitySearchCriteria,
    ArrayExtensions
} from '@msdyn365-commerce-modules/retail-actions';
import {
    ProductDimension,
    ProductDimensionAvailabilitySearchCriteria,
    ProductsDataActions,
    SimpleProduct
} from '@msdyn365-commerce/retail-proxy';
import { IProductDimensionsWithAvailabilitiesFull } from '@msdyn365-commerce/commerce-entities';

/**
 * Retrieves product dimensions with availabilities.
 * @param product - Product for which the dimensions need to be retrieved.
 * @param matchingDimensionValues - Selected dimensions - filter criteria for the dimensions API.
 * @param context - Action context.
 * @param catalogId - Catalog id.
 * @returns Array of dimensions.
 */
const getFullDimensions = async (
    product: SimpleProduct,
    matchingDimensionValues: ProductDimension[],
    context: Msdyn365.IActionContext,
    catalogId?: number
) => {
    let fullDimensions: IProductDimensionsWithAvailabilitiesFull[] = [];
    const fullDimensionPromises = (product.Dimensions ?? []).map(dimension => {
        const shippingInventoryConfiguration = createInventoryAvailabilitySearchCriteria(context, [], true, undefined, undefined);
        const searchCriteria: ProductDimensionAvailabilitySearchCriteria = {
            RequestedDimensionTypeValue: dimension.DimensionTypeValue,
            MatchingDimensionValues: matchingDimensionValues,
            DefaultWarehouseOnly: shippingInventoryConfiguration.DefaultWarehouseOnly,
            FilterByChannelFulfillmentGroup: shippingInventoryConfiguration.FilterByChannelFulfillmentGroup,
            DeliveryModeTypeFilterValue: shippingInventoryConfiguration.DeliveryModeTypeFilterValue,
            CatalogId: catalogId
        };
        const dimensionValuesPromise = ProductsDataActions.getDimensionValuesWithEstimatedAvailabilitiesAsync(
            { callerContext: context, queryResultSettings: QueryResultSettingsProxy.getPagingFromInputDataOrDefaultValue(context) },
            product.MasterProductId ? product.MasterProductId : product.RecordId,
            searchCriteria
        );

        return dimensionValuesPromise.then(dimensionValues => {
            const fullDimension: IProductDimensionsWithAvailabilitiesFull = {
                ...dimension,
                dimensionValuesWithInventory: dimensionValues
            };
            return fullDimension;
        });
    });
    fullDimensions = await Promise.all(fullDimensionPromises);
    return fullDimensions;
};

/**
 * Retrieves product variant for the given input or undefined if not found.
 * @param input - Action input.
 * @param context - Action context.
 * @param fullDimensions - Product dimensions with availabilities.
 * @returns A product variant that is available or undefined.
 */
const getProductVariant = async (context: Msdyn365.IActionContext, fullDimensions: IProductDimensionsWithAvailabilitiesFull[]) => {
    let productVariant: SimpleProduct | undefined;
    if (
        ArrayExtensions.hasElements(fullDimensions) &&
        ArrayExtensions.hasElements(fullDimensions[0].dimensionValuesWithInventory) &&
        ArrayExtensions.hasElements(fullDimensions[0].dimensionValuesWithInventory[0].ProductIds)
    ) {
        const variantProductId = fullDimensions[0].dimensionValuesWithInventory[0].ProductIds[0];
        const result = await ProductsDataActions.getByIdsAsync(
            {
                callerContext: context,
                queryResultSettings: QueryResultSettingsProxy.getPagingFromInputDataOrDefaultValue(context)
                //bypassCache: input.bypassCache
            },
            context.requestContext.apiSettings.channelId,
            [variantProductId],
            null,
            context.requestContext.apiSettings.catalogId
        );
        productVariant = result[0];
        productVariant = { ...productVariant };
        const variantImageUrl = generateProductImageUrl(productVariant, context.requestContext.apiSettings);

        if (variantImageUrl) {
            productVariant.PrimaryImageUrl = variantImageUrl;
        }
    }

    return productVariant;
};

/**
 * Checks if given product has any unmatched dimensions.
 * Modifies product entity to use matched dimension values.
 * @param product - The product to analyze and modify.
 * @param matchingDimensionValues - Matched dimensions.
 * @returns True if has any unmatched dimension, false if all dimensions are specified.
 */
const checkIfHasUnmatchedDimensions = (product: SimpleProduct, matchingDimensionValues: ProductDimension[]): boolean => {
    let hasUnmatchedDimension: boolean = false;
    if (product.Dimensions) {
        product.Dimensions = product.Dimensions.map(dimension => {
            return { ...dimension };
        });
        for (const dimension of product.Dimensions) {
            const matchedTargetDimension = matchingDimensionValues.find(
                targetDimension => targetDimension.DimensionTypeValue === dimension.DimensionTypeValue
            );

            if (matchedTargetDimension) {
                dimension.DimensionValue = matchedTargetDimension.DimensionValue;
            } else {
                hasUnmatchedDimension = true;
            }
        }
    }
    return hasUnmatchedDimension;
};

const getProductVariantOrMaster = async (
    productId: number,
    matchingDimensionValues: ProductDimension[],
    context: Msdyn365.IActionContext
) => {
    const result = await ProductsDataActions.getByIdsAsync(
        {
            callerContext: context,
            queryResultSettings: QueryResultSettingsProxy.getPagingFromInputDataOrDefaultValue(context)
            // bypassCache: input.bypassCache
        },
        context.requestContext.apiSettings.channelId,
        [productId],
        null,
        context.requestContext.apiSettings.catalogId
    );

    let product = result[0];

    // Need to dereference this before editing it. Otherwise we might not
    // properly get the mobx events because if things aren't properly observable
    // they won't fire when you set them, and then if you don't deref the value in
    // the cache will match the value when you try to save it, so it won't detect any
    // changes there either
    product = { ...product };

    // Not all dimensions are selected
    if (matchingDimensionValues.find(dv => !dv.DimensionValue)) {
        return product;
    }

    const hasUnmatchedDimension = checkIfHasUnmatchedDimensions(product, matchingDimensionValues);

    if (hasUnmatchedDimension) {
        return product;
    }

    const fullDimensions: IProductDimensionsWithAvailabilitiesFull[] = await getFullDimensions(
        product,
        matchingDimensionValues,
        context,
        context.requestContext.apiSettings.catalogId
    );

    // Retrieve product variants only if matching dimensions are provided.
    const productVariant: SimpleProduct | undefined = ArrayExtensions.hasElements(matchingDimensionValues)
        ? await getProductVariant(context, fullDimensions)
        : undefined;

    return productVariant ?? product;
};

export { getProductVariantOrMaster };
