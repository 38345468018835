// base on @msdyn365-commerce-modules/retail-actions/get-attributes-for-selected-variant

import {
    CacheType,
    createObservableDataAction,
    getCatalogId,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
import { AttributeValue, ProductsDataActions, SimpleProduct } from '@msdyn365-commerce/retail-proxy';

import {
    getSelectedProductIdFromActionInput,
    SelectedVariantInput,
    getSelectedVariantActionDataAction
    // QueryResultSettingsProxy
} from '@msdyn365-commerce-modules/retail-actions';

/**
 * Chains getting the SelectedVariant with GetActivePrice.
 */
export class AttributesForSelectedVariantInput implements IActionInput {
    public productId: number;

    public channelId: number;

    public catalogId: number;

    public selectedProduct: SimpleProduct | undefined;

    constructor(productId: number, channelId: number, selectedProduct?: SimpleProduct, catalogId?: number) {
        this.productId = productId;
        this.channelId = channelId;
        this.selectedProduct = selectedProduct;
        this.catalogId = catalogId || 0;
    }

    public getCacheKey = () => 'AttributesForSelectedVariant';

    public getCacheObjectType = () => 'ProductAttributeValue';

    public dataCacheType = (): CacheType => 'none';
}

export const createAttributesForSelectedVariantInput = (
    inputData: ICreateActionContext<IGeneric<IAny>>
): AttributesForSelectedVariantInput => {
    const productId = getSelectedProductIdFromActionInput(inputData);
    const catalogId = getCatalogId(inputData.requestContext);

    if (productId) {
        return new AttributesForSelectedVariantInput(+productId, +inputData.requestContext.apiSettings.channelId, undefined, catalogId);
    }
    throw new Error('Unable to create PriceForSelectedVariantInput, no productId found on module config or query');
};

/**
 * Calls the Retail API a get a list of dimension values for a given product.
 * @param input
 * @param ctx
 */
export async function getAttributesForSelectedVariantAction(
    input: AttributesForSelectedVariantInput,
    ctx: IActionContext
): Promise<AttributeValue[]> {
    return Promise.resolve()
        .then(() => {
            const activeProduct: SimpleProduct | undefined = input.selectedProduct;

            if (!activeProduct) {
                const selectedVariantInput = new SelectedVariantInput(
                    input.productId,
                    input.channelId,
                    undefined,
                    undefined,
                    ctx.requestContext
                );

                return getSelectedVariantActionDataAction(selectedVariantInput, ctx);
            }

            return activeProduct;
        })
        .then<AttributeValue[] | null>((productResult: SimpleProduct | null) => {
            const activeProduct: SimpleProduct | undefined = <SimpleProduct | undefined>productResult;

            if (activeProduct) {
                return ProductsDataActions.getAttributeValuesAsync(
                    {
                        callerContext: ctx
                        // queryResultSettings: QueryResultSettingsProxy.getPagingFromInputDataOrDefaultValue(ctx)
                    },
                    activeProduct.RecordId,
                    input.channelId,
                    input.catalogId
                );
            }

            return null;
        })
        .then((attributeValues: AttributeValue[] | null) => {
            const resultArray = <AttributeValue[]>attributeValues;
            if (resultArray) {
                return resultArray;
            }

            return [];
        })
        .catch((error: Error) => {
            ctx.trace(error.message);
            ctx.telemetry.exception(error);
            ctx.telemetry.debug('[getPriceForSelectedVariantAction]Error executing action');
            throw new Error('[getPriceForSelectedVariantAction]Error executing action');
        });
}

export const getAttributesForSelectedVariantActionDataAction = createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-attributes-for-selected-variant',
    action: <IAction<AttributeValue[]>>getAttributesForSelectedVariantAction,
    input: createAttributesForSelectedVariantInput
});

export default getAttributesForSelectedVariantActionDataAction;
