import * as Msdyn365 from '@msdyn365-commerce/core';
import { createObservableDataAction, IAction, IActionContext } from '@msdyn365-commerce/core';
import { ProductsDataActions, WarrantyGroup, ProductDimension } from '@msdyn365-commerce/retail-proxy';
import { getSelectedProductIdFromActionInput, getDimensionValuesFromQuery } from '@msdyn365-commerce-modules/retail-actions';
import { getProductVariantOrMaster } from './getProductVariantOrMaster';

export class getApplicableWarrantiesInput implements Msdyn365.IActionInput {
    public readonly recordId: number;
    public readonly dimensionValues: ProductDimension[];

    constructor(recordId: number, dimensionValues: ProductDimension[]) {
        this.recordId = recordId;
        this.dimensionValues = dimensionValues;
    }

    public getCacheKey = () => `${this.recordId}-${this.dimensionValues.map(v => v.DimensionValue?.Value ?? '').join('|')}`;
    public getCacheObjectType = () => 'HEI-ApplicableWarranties';
    public dataCacheType = (): Msdyn365.CacheType => 'application';
}

const createInput = (context: Msdyn365.ICreateActionContext): Msdyn365.IActionInput => {
    const matchingDimensionValues = getDimensionValuesFromQuery(context.requestContext.url.requestUrl);
    const productId = getSelectedProductIdFromActionInput(context);
    if (productId) {
        return new getApplicableWarrantiesInput(+productId, matchingDimensionValues);
    } else {
        throw new Error('Unable to create GetProductInfoInput, no productId found on module config or query');
    }
};

export async function getApplicableWarrantiesAction(
    input: getApplicableWarrantiesInput,
    context: IActionContext
): Promise<WarrantyGroup[] | undefined> {
    const matchingDimensionValues = input.dimensionValues;
    const productVariantOrMaster = await getProductVariantOrMaster(input.recordId, matchingDimensionValues, context);

    const applicableWarranties = await ProductsDataActions.getApplicableWarrantiesAsync(
        { callerContext: context },
        context.requestContext.apiSettings.catalogId,
        [productVariantOrMaster.RecordId]
    );

    const warrantyGroup = applicableWarranties.find(aw => aw.WarrantableProductId === productVariantOrMaster.RecordId);
    return warrantyGroup?.WarrantyGroups;
}

/**
 * The complete createGetApplicableWarrantiesInput data action.
 */
export const getApplicableWarrantiesActionDataAction = createObservableDataAction({
    id: 'hei-get-applicable-warranties',
    action: <IAction<WarrantyGroup[] | undefined>>getApplicableWarrantiesAction,
    input: createInput
});

export default getApplicableWarrantiesActionDataAction;
