// base on @msdyn365-commerce-modules/retail-actions/get-availabilities-cartlines

import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICommerceApiSettings,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
import { getCartState } from '@msdyn365-commerce/global-state';
import {
    CartLine,
    ChannelDeliveryOptionConfiguration,
    OrgUnitsDataActions,
    ProductsDataActions,
    ProductWarehouse,
    StoreOperationsDataActions
} from '@msdyn365-commerce/retail-proxy';

import {
    ActiveCartProductsInput,
    ArrayExtensions,
    buildCacheKey,
    createInventoryAvailabilitySearchCriteria,
    getActiveCartProductsAction,
    IProductInventoryInformation,
    mapAggregatedProductInventoryInformation,
    mapProductInventoryInformation,
    getFeatureState,
    createGetFeatureStateInput
} from '@msdyn365-commerce-modules/retail-actions';

/**
 * Input class for availabilities for items in cart.
 */
export class ProductAvailabilitiesForCartLineItems implements IActionInput {
    private readonly apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings) {
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey('ActiveCartLineItemsAvailability', this.apiSettings);

    public getCacheObjectType = () => 'ActiveCartLineItemsAvailability';

    public dataCacheType = (): CacheType => 'none';
}

const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>) => {
    return new ProductAvailabilitiesForCartLineItems(inputData.requestContext.apiSettings);
};

/**
 * Calls the Retail Feature State API and returns a list of feature with isEnabled flag.
 * @param cartLine
 * @param featureSate
 * @param channelDeliveryOptionConfig
 * @param pickupDeliveryMode
 */
const getDeliveryMode = (
    cartLine: CartLine,
    featureSate: boolean = false,
    channelDeliveryOptionConfig: ChannelDeliveryOptionConfiguration,
    pickupDeliveryMode?: string
) => {
    if (!featureSate) {
        return cartLine.DeliveryMode === pickupDeliveryMode;
    }
    return (
        cartLine.DeliveryMode ===
        channelDeliveryOptionConfig?.PickupDeliveryModeCodes?.find(deliveryMode => deliveryMode === cartLine.DeliveryMode)
    );
};

/**
 * Calls the Retail API to get the product availabilities for items in the cart.
 * @param input
 * @param ctx
 */
// eslint-disable-next-line complexity
export async function getAvailabilitiesForCartLineItems(
    input: ProductAvailabilitiesForCartLineItems,
    ctx: IActionContext
): Promise<IProductInventoryInformation[]> {
    // If no input is provided fail out
    if (!input) {
        throw new Error('[getAvailabilitiesForCartLineItems]No valid Input was provided, failing');
    }

    const shippingItems: CartLine[] = [];
    const bopisItemsDict: { [key: string]: CartLine } = {};
    let productAvailabilities: IProductInventoryInformation[] = [];
    const multiplePickupStoreSwitchName = 'Dynamics.AX.Application.RetailMultiplePickupDeliveryModeFeature';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let channelDeliveryOptionConfig: any;

    const cartState = await getCartState(ctx);
    const cart = cartState.cart;
    const channelConfiguration = await OrgUnitsDataActions.getOrgUnitConfigurationAsync({ callerContext: ctx });
    const products = await getActiveCartProductsAction(new ActiveCartProductsInput(), ctx);

    const featureState = await getFeatureState(createGetFeatureStateInput({ requestContext: ctx.requestContext }), ctx);
    const retailMultiplePickUpOptionEnabled = featureState?.find(item => item.Name === multiplePickupStoreSwitchName)?.IsEnabled;
    if (retailMultiplePickUpOptionEnabled) {
        channelDeliveryOptionConfig = await StoreOperationsDataActions.getChannelDeliveryOptionConfigurationAsync({ callerContext: ctx });
    }
    const PickupDeliveryModeCode = channelConfiguration.PickupDeliveryModeCode;
    const EmailDeliveryModeCode = channelConfiguration.EmailDeliveryModeCode;

    if (!cart || !channelConfiguration || !products || products.length === 0) {
        ctx.trace('[getAvailabilitiesForCartLineItems] Not able to get cart OR channelConfiguration or no products in cart');
        return <IProductInventoryInformation[]>[];
    }

    if (cart && cart.Id && cart.CartLines && cart.CartLines.length > 0 && channelConfiguration) {
        for (const cartLine of cart.CartLines) {
            if (
                cartLine.DeliveryMode &&
                cartLine.DeliveryMode !== '' &&
                getDeliveryMode(cartLine, retailMultiplePickUpOptionEnabled, channelDeliveryOptionConfig, PickupDeliveryModeCode)
            ) {
                bopisItemsDict[`${cartLine.ProductId}-${cartLine.WarehouseId}`] = cartLine;
            } else if (EmailDeliveryModeCode === '' || cartLine.DeliveryMode !== EmailDeliveryModeCode) {
                shippingItems.push(cartLine);
            }
        }
    }

    if (shippingItems && shippingItems.length > 0) {
        let productIds = shippingItems.map(x => x.ProductId!);
        productIds = ArrayExtensions.unique(productIds);
        const searchCriteria = createInventoryAvailabilitySearchCriteria(ctx, productIds, true);
        const shippingProductAvailabilities = await ProductsDataActions.getEstimatedAvailabilityAsync(
            { callerContext: ctx, bypassCache: 'get' },
            searchCriteria
        );

        if (
            shippingProductAvailabilities &&
            shippingProductAvailabilities.ProductWarehouseInventoryAvailabilities &&
            shippingProductAvailabilities.ProductWarehouseInventoryAvailabilities.length > 0
        ) {
            productAvailabilities = mapAggregatedProductInventoryInformation(ctx, shippingProductAvailabilities);
        }
    }

    const bopisItems = Object.keys(bopisItemsDict).map(k => bopisItemsDict[k]);
    process.env.NODE_ENV === 'development' && console.log('get-availabilities-cartlines.action', bopisItems);
    if (bopisItems && bopisItems.length > 0) {
        const productWarehouses: ProductWarehouse[] = [];

        for (const bopisItem of bopisItems) {
            const productWarehouse: ProductWarehouse = {
                ProductId: bopisItem.ProductId,
                InventLocationId: bopisItem.WarehouseId
            };

            if (ctx.requestContext.channel && ctx.requestContext.channel.InventLocationDataAreaId) {
                productWarehouse.DataAreaId = ctx.requestContext.channel.InventLocationDataAreaId;
            }
            productWarehouses.push(productWarehouse);
        }

        const quantityUnitTypeSales: number = 2;
        const getProductWarehouseAvail = await ProductsDataActions.getEstimatedProductWarehouseAvailabilityAsync(
            { callerContext: ctx, bypassCache: 'get', queryResultSettings: {} },
            productWarehouses,
            quantityUnitTypeSales
        );

        if (
            getProductWarehouseAvail &&
            getProductWarehouseAvail.ProductWarehouseInventoryAvailabilities &&
            getProductWarehouseAvail.ProductWarehouseInventoryAvailabilities.length > 0
        ) {
            const productWarehouseMapping = mapProductInventoryInformation(
                ctx,
                getProductWarehouseAvail?.ProductWarehouseInventoryAvailabilities
            );
            if (productWarehouseMapping && productWarehouseMapping.length > 0) {
                for (const item of productWarehouseMapping) {
                    productAvailabilities.push(item);
                }
            }
        }
    }

    if (productAvailabilities && productAvailabilities.length > 0) {
        return productAvailabilities;
    }

    ctx.trace('[getAvailabilitiesForCartLineItems] unable to get availabilities for product');
    return <IProductInventoryInformation[]>[];
}

export const getAvailabilitiesForCartLineItemsDataAction = createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-availabilities-cartlines',
    action: <IAction<IProductInventoryInformation[]>>getAvailabilitiesForCartLineItems,
    input: createInput
});

export default getAvailabilitiesForCartLineItemsDataAction;
