// base on @msdyn365-commerce-modules/retail-actions/get-full-available-inventory-nearby

import { createObservableDataAction, IAction, ICreateActionContext, IGeneric, IAny, IActionContext } from '@msdyn365-commerce/core';
import {
    ChannelDeliveryOption,
    DeliveryModeTypeFilter,
    ItemAvailability,
    OrgUnitAvailability,
    OrgUnitLocation,
    ProductWarehouseInventoryInformation,
    SearchArea,
    StoreHours,
    ProductsDataActions,
    OrgUnitsDataActions
} from '@msdyn365-commerce/retail-proxy';
import {
    getDimensionValuesFromQuery,
    IFullOrgUnitAvailability,
    DeliveryMode,
    IProductInventoryInformation,
    QueryResultSettingsProxy,
    createInventoryAvailabilitySearchCriteria,
    mapProductInventoryInformation,
    GetFullAvailableInventoryNearbyInput,
    getFeatureState,
    createGetFeatureStateInput
} from '@msdyn365-commerce-modules/retail-actions';
import { GetProductPromotionsInput, getProductPromotionsAction } from './get-product-promotions.action';

export const mergeBonusProductInventoryInformation = (
    productInventoryInformation: IProductInventoryInformation[],
    productId: number,
    bonusItemIds: number[]
) => {
    // Merge availabilities with bonus items
    if (bonusItemIds.length > 0) {
        const mergedProductInventoryInformation = productInventoryInformation
            .filter(p => p.ProductAvailableQuantity.ProductId === productId)
            .map(p => {
                productInventoryInformation
                    .filter(
                        bonusItemInv =>
                            bonusItemInv.InventLocationId === p.InventLocationId &&
                            bonusItemInv.ProductAvailableQuantity.ProductId !== productId
                    )
                    .forEach(bonusItemInv => {
                        if (
                            (bonusItemInv.ProductAvailableQuantity.AvailableQuantity ?? 0) === 0 &&
                            (p.ProductAvailableQuantity.AvailableQuantity ?? 0) > 0
                        ) {
                            process.env.NODE_ENV === 'development' &&
                                console.log('getFullAvailableInventoryNearbyAction', 'missing bonus item', p, bonusItemInv);

                            p.ProductAvailableQuantity.AvailableQuantity = bonusItemInv.ProductAvailableQuantity.AvailableQuantity;
                            p.IsProductAvailable = false;
                            p.StockLevelCode = bonusItemInv.StockLevelCode;
                            p.StockLevelLabel = bonusItemInv.StockLevelLabel;
                        }
                    });
                return p;
            });

        process.env.NODE_ENV === 'development' && console.log('getFullAvailableInventoryNearbyAction', mergedProductInventoryInformation);
        return mergedProductInventoryInformation;
    }

    return productInventoryInformation;
};

/**
 * CreateInput method for the getSelectedVariant data action.
 * @param inputData - The input data passed to the createInput method.
 * @returns GetFullAvailableInventoryNearbyInput - The action input.
 */
export const createGetFullAvailableInventoryNearbyInput = (
    inputData: ICreateActionContext<IGeneric<IAny>>
): GetFullAvailableInventoryNearbyInput => {
    return new GetFullAvailableInventoryNearbyInput();
};

/**
 * Action method for the getSelectedVariant data aciton.
 * @param input - The action input class.
 * @param ctx - The action context.
 * @returns IFullOrgUnitAvailability - The full org unit availability.
 */
export async function getFullAvailableInventoryNearbyAction(
    input: GetFullAvailableInventoryNearbyInput,
    ctx: IActionContext
): Promise<IFullOrgUnitAvailability[] | undefined> {
    // No valid product we want to return undefined so module knows there are no results yet
    if (!input.productId) {
        return undefined;
    }

    if (((!input.radius && input.radius !== 0) || !input.latitude || !input.longitude) && !input.IgnoreLocation) {
        // No valid location we want to return empty array so module can show no locations message
        return [];
    }

    const searchArea: SearchArea = {
        Latitude: input.latitude,
        Longitude: input.longitude,
        Radius: input.radius,
        DistanceUnitValue: input.DistanceUnitValue || 0 // 0 is miles
    };

    const featureState = await getFeatureState(createGetFeatureStateInput({ requestContext: ctx.requestContext }), ctx);
    const retailMulitplePickupMFeatureState = featureState?.find(
        item => item.Name === 'Dynamics.AX.Application.RetailMultiplePickupDeliveryModeFeature'
    )?.IsEnabled;

    const matchingDimensionValues = getDimensionValuesFromQuery(ctx.requestContext.url.requestUrl);
    const getProductPromotionsInput = new GetProductPromotionsInput(input.productId, matchingDimensionValues);
    const promotions = await getProductPromotionsAction(getProductPromotionsInput, ctx);
    const bonusItemIds = promotions.FreeItemProductIds ?? [];

    process.env.NODE_ENV === 'development' && console.log('getFullAvailableInventoryNearbyAction', bonusItemIds);

    const searchCriteria = createInventoryAvailabilitySearchCriteria(
        ctx,
        [input.productId, ...bonusItemIds],
        false,
        true,
        searchArea,
        DeliveryMode.pickup
    );
    return ProductsDataActions.getEstimatedAvailabilityAsync({ callerContext: ctx }, searchCriteria)
        .then(async (productWarehouseInformation: ProductWarehouseInventoryInformation) => {
            // For store selector, inventory should always come from an individual store
            let productInventoryInformation = mapProductInventoryInformation(
                ctx,
                productWarehouseInformation.ProductWarehouseInventoryAvailabilities
            );

            process.env.NODE_ENV === 'development' && console.log('getFullAvailableInventoryNearbyAction', productInventoryInformation);

            productInventoryInformation = mergeBonusProductInventoryInformation(
                productInventoryInformation,
                input.productId!,
                bonusItemIds
            );

            return OrgUnitsDataActions.getOrgUnitLocationsByAreaAsync(
                {
                    callerContext: ctx,
                    queryResultSettings: QueryResultSettingsProxy.getPagingFromInputDataOrDefaultValue(ctx)
                },
                searchArea,
                DeliveryModeTypeFilter.Pickup
            )
                .then(async (stores: OrgUnitLocation[]) => {
                    // Constructing a store mapping based on the InventoryId.
                    const storeMap = new Map<string, OrgUnitLocation>();
                    stores.forEach(store => {
                        if (store.InventoryLocationId) {
                            storeMap.set(store.InventoryLocationId, store);
                        }
                    });

                    let locationDeliveryOptions: ChannelDeliveryOption[] | undefined = [];

                    // If multiple pickup mode is enable then call getchanneldeliveryoption
                    if (retailMulitplePickupMFeatureState) {
                        const orgUnitChannel = stores.map(store => store.ChannelId);
                        locationDeliveryOptions = await _getLocationPickUpDeliveryModes(orgUnitChannel, ctx);
                    }

                    const availabilityPromiseList = stores.map(store => {
                        const locationDeliveryOption = locationDeliveryOptions?.find(
                            _channeldeliveryoption => _channeldeliveryoption.ChannelId === store.ChannelId
                        );
                        return _getAvailabilityWithHours(store, productInventoryInformation, storeMap, ctx, locationDeliveryOption);
                    });

                    return Promise.all(availabilityPromiseList);
                })
                .catch((error: Error) => {
                    ctx.trace('[GetFullAvailableInventoryNearby] error getting Available Inventory Nearby');
                    ctx.trace(error.message);
                    ctx.telemetry.error(error.message);
                    ctx.telemetry.debug('[GetFullAvailableInventoryNearby] error getting Available Inventory Nearby');
                    return [];
                });
        })
        .catch((error: Error) => {
            ctx.trace(
                '[GetFullAvailableInventoryNearby][getEstimatedAvailabilityAsync] error getting availability product warehouse information.'
            );
            ctx.trace(error.message);
            ctx.telemetry.error(error.message);
            ctx.telemetry.debug(
                '[GetFullAvailableInventoryNearby][getEstimatedAvailabilityAsync] error getting availability product warehouse information.'
            );
            return [];
        });
}

/**
 * Action method that obtains the store information along with store hours and product availability.
 * @param orgUnitLocation - The org unit location.
 * @param productInventoryInformation - The product inventory information.
 * @param storeMap - A map that contains store information group by the inventory location id.
 * @param ctx The action context.
 * @param channelDeleiveryOptions - The channel delivery options.
 * @returns IFullOrgUnitAvailability - The full org unit availability.
 */
async function _getAvailabilityWithHours(
    orgUnitLocation: OrgUnitLocation,
    productInventoryInformation: IProductInventoryInformation[],
    storeMap: Map<string, OrgUnitLocation>,
    ctx: IActionContext,
    channelDeleiveryOptions: ChannelDeliveryOption | undefined
): Promise<IFullOrgUnitAvailability> {
    if (!orgUnitLocation || !orgUnitLocation.OrgUnitNumber) {
        return { OrgUnitAvailability: undefined };
    }

    return OrgUnitsDataActions.getStoreHoursAsync({ callerContext: ctx }, orgUnitLocation.OrgUnitNumber)
        .then((hours: StoreHours) => {
            const itemAvailabilities: ItemAvailability[] = [];
            if (productInventoryInformation && storeMap) {
                productInventoryInformation.forEach(element => {
                    if (
                        element.InventLocationId &&
                        storeMap.has(element.InventLocationId) &&
                        element.InventLocationId === orgUnitLocation.InventoryLocationId
                    ) {
                        itemAvailabilities.push({ AvailableQuantity: element.ProductAvailableQuantity?.AvailableQuantity });
                    }
                });
            }

            const availability: OrgUnitAvailability = {
                OrgUnitLocation: orgUnitLocation,
                ItemAvailabilities: itemAvailabilities
            };

            if (hours && !(hours instanceof Error)) {
                return {
                    OrgUnitAvailability: availability,
                    StoreHours: hours,
                    ProductInventoryInformation: productInventoryInformation,
                    OrgUnitPickUpDeliveryModes: channelDeleiveryOptions
                };
            }

            return {
                OrgUnitAvailability: availability,
                ProductInventoryInformation: productInventoryInformation,
                OrgUnitPickUpDeliveryModes: channelDeleiveryOptions
            };
        })
        .catch((error: Error) => {
            ctx.trace('[GetFullAvailableInventoryNearby] error getting availability with hours');
            ctx.trace(error.message);
            ctx.telemetry.exception(error);
            ctx.telemetry.debug('[GetFullAvailableInventoryNearby] error getting availability with hours');
            return { OrgUnitAvailability: {} };
        });
}

/**
 * Action method that obtains the channel delivery option information.
 * @param channelCollection - The org unit channel Id list.
 * @param ctx - The action context.
 * @returns ChannelDeliveryOption - The channel delivery option collection.
 */
async function _getLocationPickUpDeliveryModes(
    channelCollection: (number | undefined)[],
    ctx: IActionContext
): Promise<ChannelDeliveryOption[] | undefined> {
    if (channelCollection?.length === 0 || channelCollection === undefined) {
        return undefined;
    }

    const channelIdList: number[] = [];
    channelCollection?.forEach(id => {
        if (id !== undefined) {
            channelIdList.push(id);
        }
    });

    // To get all channel pickup delivery mode filterOption should be 4
    return OrgUnitsDataActions.getChannelDeliveryOptionsAsync(
        {
            callerContext: ctx,
            queryResultSettings: QueryResultSettingsProxy.getPagingFromInputDataOrDefaultValue(ctx)
        },
        channelIdList,
        4
    )
        .then((channelDeliveryOptions: ChannelDeliveryOption[]) => {
            if (channelDeliveryOptions && !(channelDeliveryOptions instanceof Error)) {
                return channelDeliveryOptions;
            }

            return undefined;
        })
        .catch((error: Error) => {
            ctx.trace(
                '[GetFullAvailableInventoryNearby][getChannelDeliveryOptionsAsync] error getting availability with channel delivery options'
            );
            ctx.trace(error.message);
            ctx.telemetry.exception(error);
            ctx.telemetry.debug(
                '[GetFullAvailableInventoryNearby] [getChannelDeliveryOptionsAsync] error getting availability with channel delivery options'
            );
            return [];
        });
}

/**
 * The complete getFullAvailableInventoryNearby data action.
 */
export const getFullAvailableInventoryNearbyActionDataAction = createObservableDataAction({
    id: 'hei-get-full-available-inventory-nearby',
    action: <IAction<IFullOrgUnitAvailability[] | undefined>>getFullAvailableInventoryNearbyAction,
    input: createGetFullAvailableInventoryNearbyInput
});

export default getFullAvailableInventoryNearbyActionDataAction;
